import { Link, navigate } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import DarkLogo from '../../images/logo-dark.png'
import LightLogo from '../../images/logo-light.png'
import './header.less'

let initial = false

if (typeof window !== 'undefined') {
  const initial_url = window.location.pathname

  if (initial_url === '/' || initial_url === '') {
    initial = true
  }
  if (initial_url === '/who-we-are' || initial_url === '/who-we-are/') {
    initial = true
  }
}

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      menuActive: false,
      scrollHeight: 0,
      home: true,
      dark_bg: true,
      header: 'visible',
      single_project: '',
    }
  }

  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      const uikit = require('uikit')
      const icons = require('uikit/dist/js/uikit-icons.min')
      uikit.use(icons)
    }

    if (initial) {
      setTimeout(() => {
        this.setState({ header: 'visible' })
      }, 6000)
      initial = false
    } else {
      setTimeout(() => {
        this.setState({ header: 'visible' })
      }, 1600)
    }
    document.addEventListener('scroll', this.handleScroll, false)

    if (typeof window !== 'undefined') {
      const url = window.location.pathname

      this.setState({
        home: url === '/' || url === '',
      })

      if (url === '/' || url === '') {
        this.setState({ header: 'hidden' })
      }
      if (url === '/who-we-are' || url === '/who-we-are/') {
        this.setState({ header: 'hidden' })
        this.setState({ dark_bg: false })
      }
      if (url === '/lets-talk' || url === '/lets-talk/') {
        this.setState({ header: 'hidden' })
        this.setState({ dark_bg: false })
      }
      if (url === '/featured-projects' || url === '/featured-projects/') {
        this.setState({ header: 'hidden' })
        if (window.innerWidth < 960) {
          this.setState({ dark_bg: false })
        } else {
          this.setState({ dark_bg: true })
        }
      }

      // set color of project page
      if (typeof url.split('/')[2] !== 'undefined') {
        if (url.split('/')[1] === 'featured-projects') {
          if (window.innerWidth < 640) {
            this.setState({ dark_bg: false })
          } else if (window.innerWidth < 960) {
            this.setState({ dark_bg: false })
          } else {
            this.setState({ dark_bg: true })
          }
        }
      }
    }
  }

  handleScroll = () => {
    this.setState({
      scrollHeight: window.scrollY,
    })
    // Change menu button color in who we are page
    if (
      typeof document !== 'undefined' &&
      document.getElementById('text_scroll')
    ) {
      this.setState({
        menu_bg_trigger_point:
          document.getElementById('text_scroll').offsetTop - 60,
      })

      if (this.state.scrollHeight >= this.state.menu_bg_trigger_point) {
        this.setState({
          dark_bg: true,
        })
      } else {
        this.setState({
          dark_bg: false,
        })
      }
    }

    // Change menu button color in project pages
    if (
      typeof window !== 'undefined' &&
      typeof document !== 'undefined' &&
      document.getElementById('start_dark_menu')
    ) {
      this.setState({
        menu_bg_trigger_point:
          document.getElementById('start_dark_menu').offsetTop - 60,
      })

      if (window.innerWidth < 960) {
        // mobile styles
        if (this.state.scrollHeight < window.innerHeight / 2) {
          this.setState({
            dark_bg: false,
          })
        } else if (this.state.scrollHeight < this.state.menu_bg_trigger_point) {
          this.setState({
            dark_bg: true,
          })
        } else {
          this.setState({
            dark_bg: false,
          })
        }
      } else {
        // desktop
        if (this.state.scrollHeight >= this.state.menu_bg_trigger_point) {
          this.setState({
            dark_bg: false,
          })
        } else {
          this.setState({
            dark_bg: true,
          })
        }
      }
    }
  }

  handle_click = (e) => {
    e.preventDefault()
    if (typeof window !== 'undefined' && window.location.pathname !== '/') {
      document.body.classList.add('cover')
      setTimeout(() => {
        navigate('/')
      }, 700)
    }
  }

  menu_nav = (e, link, white = false) => {
    e.preventDefault()
    this.toggleMenu()
    if (typeof window !== 'undefined' && window.location.pathname !== link) {
      setTimeout(() => {
        if (white) {
          document.body.classList.add('cover', 'white')
        } else {
          document.body.classList.add('cover')
        }
      }, 400)
      setTimeout(() => {
        navigate(link)
        this.setState({ header: 'hidden' })
      }, 1000)
    }
  }

  toggleMenu = () => {
    this.setState({
      menuActive: !this.state.menuActive,
    })
    !this.state.menuActive
      ? document.body.classList.add('stop-scroll')
      : document.body.classList.remove('stop-scroll')
  }

  render() {
    const opacityControlHeight =
      typeof window !== 'undefined' ? window.innerHeight / 5 : 100
    let opacity = 1

    if (this.state.scrollHeight === 0) {
      opacity = 1
    } else if (this.state.scrollHeight < opacityControlHeight) {
      opacity =
        (opacityControlHeight - this.state.scrollHeight) / opacityControlHeight
    } else {
      opacity = 0
    }

    return (
      <header className={this.state.header}>
        <div
          className={this.state.menuActive ? 'menu-slider show' : 'menu-slider'}
        >
          <div className="menu-header">
            <span
              onClick={(e) => this.menu_nav(e, '/')}
              className="linked-logo"
            >
              <img src={DarkLogo} alt="Logo" />
            </span>

            <button
              className="menu-button uk-button-link"
              onClick={this.toggleMenu}
            >
              <span></span>
            </button>
          </div>

          <div className="body">
            <ul className="menu">
              <li>
                <span>Work</span>
                <Link
                  to="/featured-projects"
                  onClick={(e) => this.menu_nav(e, '/featured-projects', true)}
                >
                  Featured projects
                </Link>
              </li>
              <li>
                <span>Studio</span>
                <Link
                  to="/who-we-are"
                  onClick={(e) => this.menu_nav(e, '/who-we-are', true)}
                >
                  Who we are
                </Link>
              </li>
              <li>
                <span>Contact</span>
                <Link
                  to="/lets-talk"
                  onClick={(e) => this.menu_nav(e, '/lets-talk', true)}
                >
                  Let's talk
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <span
          to="/"
          onClick={(e) => this.handle_click(e)}
          style={{ opacity }}
          className="linked-logo"
        >
          {this.state.home ? (
            <img src={DarkLogo} alt="Logo" />
          ) : (
            <img src={LightLogo} alt="Logo" />
          )}
        </span>

        <button
          className={
            this.state.menuActive
              ? 'menu-button uk-button-link menu-visible' +
                this.state.single_project
              : 'menu-button uk-button-link' + this.state.single_project
          }
          onClick={this.toggleMenu}
        >
          <span className={this.state.dark_bg ? 'white' : 'black'}></span>
          <span className={this.state.dark_bg ? 'white' : 'black'}></span>
          <span className={this.state.dark_bg ? 'white' : 'black'}></span>
        </button>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
  opacity: PropTypes.number,
  handler: PropTypes.func,
  menuTop: PropTypes.number,
}

Header.defaultProps = {
  siteTitle: ``,
  opacity: 1,
  menuTop: -100,
}

export default Header
