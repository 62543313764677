import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
// import '../styles/font1.css'
import '../styles/font2.css'
import '../styles/MyFontsWebfontsKit/MyFontsWebfontsKit.css'
import './layout.less'

setTimeout(() => {
  document.body.classList.add('show-initial-loader')
}, 200)

if (typeof window !== 'undefined') {
  if (window.location.pathname === '/' || window.location.pathname === '') {
    setTimeout(() => {
      document.body.classList.add('fade-initial-loader-home')
    }, 3500)
  } else {
    setTimeout(() => {
      document.body.classList.add('fade-initial-loader')
    }, 3500)
  }
}

const Layout = ({ children, handler }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <div>
        {children}
        <Header siteTitle={data.site.siteMetadata.title} handler={handler} />
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  opacity: PropTypes.number,
  handleClick: PropTypes.func,
  menuTop: PropTypes.number,
}

export default Layout
